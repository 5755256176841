// DO NOT MODIFY! THIS FILE IS AUTOGENERATED BY generate-endpoint-spec.util.ts

import { RequestHandler } from 'express';

export const TSpecRoute_moveBehavior = () => "/behaviors/reorder-multiple";

export type TSpecHandler_moveBehavior = RequestHandler<TSpecPathMoveBehavior, any, TSpecBodyMoveBehavior, TSpecQueryMoveBehavior, {}>;

export interface TSpecPathMoveBehavior {  }

export interface TSpecBodyMoveBehavior {
  organizedIds: string[];
}

export interface TSpecQueryMoveBehavior {
  AppVersion?: string;
  AppUser?: string;
}
