import { NgModule, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamSettingsComponent } from './team-settings.component';
import { RouterModule, Routes } from '@angular/router';
import { PageWrapperModule } from '@app/modules/page-wrapper/page-wrapper.module';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TenantService } from '@app/core/service/tenant.service';
import { take } from 'rxjs';
import { DateFormControlModule } from '@app/modules/date-form-control/date-form-control.module';
import { SlimMenuButtonComponent } from '@app/modules/slim-menu-button/slim-menu-button.component';
import { TransformPayrollFrequencyPipe } from './transform-payroll-frequency.pipe';
import { TransformDailySurveyFrequencyPipe } from './transform-daily-survey-frequncy.pipe';
import { AllowedInsightsPipe } from './components/security-settings/insights-allowed.pipe';
import { DirectivesModule } from '@app/modules/directives.module';
import { PasscodesListModalComponent } from '@app/modules/passcodes-list-modal/passcodes-list-modal.component';
import { ReceiveDailyRepotsPipe } from './receive-daily-reports.pipe';
import { TimeFormControlModule } from '@app/modules/time-form-control/time-form-control.module';
import { BehaviorsEditComponent } from './components/behaviors-edit/behaviors-edit.component';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';
import { CompensationPoolModalModule } from '@app/modals/compensation-pool-modal/compensation-pool-modal.module';
import { CompensationFieldModalModule } from '@app/modals/compensation-field-modal/compensation-field-modal.module';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { EditSystemEntityModalModule } from '@app/modals/edit-system-entity-modal/edit-system-entity-modal.module';
import { EditNonSystemEntityModalModule } from '@app/modals/edit-non-system-entity-modal/edit-non-system-entity-modal.module';
import { GeneralSettingsComponent } from './components/general-settings/general-settings.component';
import { ContentTitleModule } from '@app/modules/content-title/content-title.module';
import { SecuritySettingsComponent } from './components/security-settings/security-settings.component';
import { ListPreLineModule } from '../../modules/list-pre-line/list-pre-line.module';
import { ListPostLineModule } from '@app/modules/list-post-line/list-post-line.module';
import { ChecklistSettingsComponent } from './components/checklist-settings/checklist-settings.component';
import { TagsSettingsComponent } from './components/tags-settings/tags-settings.component';
import { AnnouncementsSettingsComponent } from './components/announcements-settings/announcements-settings.component';
import { SuggestionsSettingsComponent } from './components/suggestions-settings/suggestions-settings.component';
import { DailyReportSettingsComponent } from './components/daily-report-settings/daily-report-settings.component';
import { PulseSettingsComponent } from './components/pulse-settings/pulse-settings.component';
import { CompensationSettingsComponent } from './components/compensation-settings/compensation-settings.component';
import { RecognitionSettingsComponent } from './components/recognition-settings/recognition-settings.component';
import { SuggestionCategoryApiService } from '@app/core/service/api/suggestionCategory.api.service';
import { SuggestionCategoryModalModule } from '@app/modals/suggestion-category-modal/suggestion-category-modal.module';
import { DepartmentModalModule } from '@app/modals/department-modal/department-modal.module';
import { DepartmentApiService } from '@app/core/service/api/department.api.service';
import { UserSelectorModalModule } from '@app/modals/user-selector-modal/user-selector-modal.module';
import { TimezoneSelectorModalModule } from '@app/modals/timezone-selector-modal/timezone-selector-modal.module';
import { FullHeightContainerModule } from '../../modules/full-height-container/full-height-container.module';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    resolve: {
      tenant: () => inject(TenantService).tenant$.pipe(take(1))
    },
    data: {
      title: 'Behaviors',
      rootComponent: TeamSettingsComponent
    }
  }
];

@NgModule({
  declarations: [
    TeamSettingsComponent,
    BehaviorsEditComponent,
    GeneralSettingsComponent,
    SecuritySettingsComponent,
    ChecklistSettingsComponent,
    TagsSettingsComponent,
    AnnouncementsSettingsComponent,
    SuggestionsSettingsComponent,
    DailyReportSettingsComponent,
    PulseSettingsComponent,
    CompensationSettingsComponent,
    RecognitionSettingsComponent,
    TransformPayrollFrequencyPipe,
    TransformDailySurveyFrequencyPipe,
    AllowedInsightsPipe,
    ReceiveDailyRepotsPipe
  ],
  imports: [
    NavBaseComponent,
    CommonModule,
    RouterModule.forChild(ROUTES),
    PageWrapperModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    DateFormControlModule,
    SlimMenuButtonComponent,
    PasscodesListModalComponent,
    DirectivesModule,
    TimeFormControlModule,
    CompensationPoolModalModule,
    CompensationFieldModalModule,
    DigitOnlyModule,
    EditSystemEntityModalModule,
    EditNonSystemEntityModalModule,
    ContentTitleModule,
    ListPreLineModule,
    ListPostLineModule,
    SuggestionCategoryModalModule,
    DepartmentModalModule,
    TimezoneSelectorModalModule,
    UserSelectorModalModule,
    FullHeightContainerModule
  ],
  providers: [SuggestionCategoryApiService, DepartmentApiService]
})
export class TeamSettingsModule {}
