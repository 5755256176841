import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { ModalClass } from '@app/core/class/modal.class';
import fastDeepEqual from 'fast-deep-equal';

export interface INonSystemEntity {
  name: string;
  points: number;
  whoEligible: 'Everyone' | 'Admin+Manager' | 'Admin';
  isValue: boolean;
  description: string;
}

@Component({
  selector: 'app-edit-non-system-entity-modal',
  templateUrl: './edit-non-system-entity-modal.component.html'
})
export class EditNonSystemEntityModalComponent
  extends ModalClass
  implements OnInit
{
  @Input()
  public readonly entity: INonSystemEntity = null;

  protected form = new FormGroup({
    name: new FormControl<string>('', Validators.required),
    points: new FormControl<string>('', Validators.required),
    whoEligible: new FormControl<'Everyone' | 'Admin+Manager' | 'Admin'>(
      'Everyone',
      Validators.required
    ),
    isValue: new FormControl<boolean>(false),
    description: new FormControl<string>('', Validators.required)
  });

  public constructor(private readonly _modalCtrl: ModalController) {
    super();
  }

  public ngOnInit(): void {
    if (this.entity) {
      this.form.reset({
        name: this.entity.name,
        points: this.entity.points.toString(),
        whoEligible: this.entity.whoEligible,
        isValue: this.entity.isValue,
        description: this.entity.description
      });
    }
  }

  protected get canSave() {
    if (!this.form.valid) return false;

    if (this.entity) {
      return !fastDeepEqual(
        {
          name: this.entity.name,
          points: this.entity.points.toString(),
          whoEligible: this.entity.whoEligible,
          isValue: this.entity.isValue,
          description: this.entity.description
        },
        {
          name: this.form.get('name').value,
          points: this.form.get('points').value || '0',
          whoEligible: this.form.get('whoEligible').value,
          isValue: this.form.get('isValue').value,
          description: this.form.get('description')
        }
      );
    }

    return true;
  }

  protected onCloseClick(): void {
    this._modalCtrl.dismiss(null, 'cancel', this._modalName);
  }

  protected onSaveClick(): void {
    const result: INonSystemEntity = {
      points: Number(this.form.value.points) || 0,
      name: this.form.value.name,
      whoEligible: this.form.value.whoEligible as
        | 'Everyone'
        | 'Admin+Manager'
        | 'Admin',
      isValue: this.form.value.isValue,
      description: this.form.value.description
    };

    this._modalCtrl.dismiss(result, 'confirm', this._modalName);
  }

  protected onDeleteClick(): void {
    this._modalCtrl.dismiss(null, 'delete', this._modalName);
  }
}
