<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
        <ion-label>Back</ion-label>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-capitalize"> Behavior </ion-title>
  </ion-toolbar>
  <ion-toolbar class="app-toolbar">
    <ion-segment [value]="currentTab" (ionChange)="onCurrentTabChange($event)">
      <ion-segment-button value="behaviors">
        <ion-label>Recognition</ion-label>
      </ion-segment-button>
      <ion-segment-button value="system">
        <ion-label>System</ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-full-height-container [isFullHeight]="true">
    <div class="behaviors-list" [attr.data-hidden]="currentTab !== 'behaviors'">
      <ion-list [inset]="true">
        <ion-item
          class="behaviors-list__item behaviors-list__head"
          lines="full"
        >
          <div class="behaviors-list__item-text behaviors-list__item-start">
            <ion-label>Behavior</ion-label>
          </div>
          <div class="behaviors-list__item-end" slot="end">
            <ion-label>Value</ion-label>
          </div>
        </ion-item>

        <ion-reorder-group
          class="reorder-group-with-wrappers"
          [disabled]="false"
          (ionItemReorder)="handleNonSysBehaviorReorder($event)"
        >
          <ion-item
            *ngFor="let behavior of recognitionBehaviors"
            class="behaviors-list__item"
            button
            (click)="onNonSysBehaviorClick(behavior)"
          >
            <div class="behaviors-list__item-text">
              <ion-label class="ion-text-capitalize">
                {{ behavior.name }}
              </ion-label>
              <ion-label class="behaviors-list__item-subtext">
                Points: {{ behavior.points }}
              </ion-label>
            </div>
            <div
              *ngIf="behavior.isValue"
              class="behaviors-list__item-end"
              slot="end"
            >
              <ion-checkbox
                [checked]="behavior.isValue"
                [disabled]="true"
                aria-label="Is Value"
              />
            </div>
            <ion-reorder slot="start">
              <ion-icon
                class="reorder-handle-icon"
                name="reorder-two-outline"
              />
            </ion-reorder>
          </ion-item>
        </ion-reorder-group>
      </ion-list>
    </div>

    <div *ngIf="currentTab === 'system'" class="behaviors-list">
      <ion-list [inset]="true">
        <ion-item
          class="behaviors-list__item behaviors-list__head"
          lines="full"
        >
          <div class="behaviors-list__item-text">
            <ion-label>System Behavior</ion-label>
          </div>
          <div class="behaviors-list__item-end" slot="end">
            <ion-label>Module</ion-label>
          </div>
        </ion-item>

        <ion-item
          *ngFor="let behavior of sysGen.behaviors"
          class="behaviors-list__item"
          button
          (click)="onSysBehaviorClick(behavior)"
        >
          <div class="behaviors-list__item-text">
            <ion-label class="ion-text-capitalize">
              {{ behavior.name }}
            </ion-label>
            <ion-label class="behaviors-list__item-subtext">
              Points: {{ behavior.points }}
            </ion-label>
          </div>
          <div
            *ngIf="behavior.module"
            class="behaviors-list__item-end"
            slot="end"
          >
            <ion-chip [color]="behavior.module.chipColor" disabled>
              {{ behavior.module.chipText }}
            </ion-chip>
          </div>
        </ion-item>

        <ion-item
          *ngFor="let task of sysGen.tasks"
          class="behaviors-list__item"
          button
          (click)="onSysTaskClick(task)"
        >
          <div class="behaviors-list__item-text">
            <ion-label class="ion-text-capitalize">
              {{ task.title }}
            </ion-label>
            <ion-label class="behaviors-list__item-subtext">
              Points: {{ task.points }}
            </ion-label>
          </div>
          <div *ngIf="task.module" class="behaviors-list__item-end" slot="end">
            <ion-chip [color]="task.module.chipColor" disabled>
              {{ task.module.chipText }}
            </ion-chip>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </app-full-height-container>

  <ion-fab
    slot="fixed"
    vertical="bottom"
    horizontal="end"
    *ngIf="currentTab === 'behaviors'"
    class="main-fab"
  >
    <ion-fab-button
      color="dark"
      (click)="onNonSysBehaviorAddClick()"
      appHaptics
    >
      <ion-icon name="add" />
    </ion-fab-button>
  </ion-fab>
</ion-content>

<ion-loading #loading message="Loading..." />
