import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { DepartmentApiService } from '@app/core/service/api/department.api.service';
import { TimezoneApiService } from '@app/core/service/api/timezone.api.service';
import { TenantService } from '@app/core/service/tenant.service';
import { ToastService } from '@app/core/service/toast.service';
import { DepartmentModalComponent } from '@app/modals/department-modal/department-modal.component';
import { TimezoneSelectorModalComponent } from '@app/modals/timezone-selector-modal/timezone-selector-modal.component';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { showModal } from '@app/utils/modal';
import { IDepartment } from '@backend/models/types/department';
import { ITenantFeatures } from '@backend/models/types/tenant';
import { TSpecBodyPatchTenant } from '@backend/types/generated/Tenants/patchTenant';
import { ITimezone } from '@backend/types/timezone';
import { IonLoading, IonNav, ModalController } from '@ionic/angular';
import { IonModal } from '@ionic/angular/common';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html'
})
export class GeneralSettingsComponent implements AfterViewInit {
  @ViewChild('loading', { static: true })
  public loading!: IonLoading;

  @ViewChild('timezoneSelectorModal', { static: true })
  public timezoneSelectorModal!: IonModal;

  protected tenantFeatures: Pick<
    ITenantFeatures,
    'announcements' | 'checklist' | 'tags'
  > = {
    announcements: false,
    checklist: false,
    tags: false
  };
  protected timezones: ITimezone[] = [];
  protected companyName: string;
  protected timezone: string;
  protected loginRedirectsTo: string;
  protected departments: MongoStoredObject<IDepartment>[] = [];

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _tenantService: TenantService,
    private readonly _timezoneApiService: TimezoneApiService,
    private readonly _departmentApiService: DepartmentApiService,
    private readonly _toastService: ToastService,
    private readonly _modalCtrl: ModalController
  ) {
    this._tenantService.tenant$.subscribe((tenant) => {
      if (this.loading) {
        this.loading.dismiss();
      }

      this.tenantFeatures = tenant.features;
      this.companyName = tenant.name;
      this.timezone = tenant.timezone;

      if (
        (tenant.loginRedirectsTo === '/i/checklist' &&
          !this.tenantFeatures.checklist) ||
        (tenant.loginRedirectsTo === '/i/tags' && !this.tenantFeatures.tags) ||
        (tenant.loginRedirectsTo === '/i/announcements' &&
          !this.tenantFeatures.announcements)
      ) {
        this.loginRedirectsTo = '/i/recognition';
      } else {
        this.loginRedirectsTo = tenant.loginRedirectsTo;
      }
    });
  }

  public ngAfterViewInit(): void {
    this._loadData();
  }

  private _loadData() {
    this.loading.present();
    combineLatest([
      this._departmentApiService.getDepartments(),
      this._timezoneApiService.getTimezones()
    ]).subscribe({
      next: ([departments, timezones]) => {
        this.departments = departments;
        this.timezones = timezones;
        this.loading.dismiss();
      },
      error: (e) => {
        this.loading.dismiss();
        this._toastService.presentToast(
          e.error?.message || 'Unable to fetch general settings'
        );
      }
    });
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  private _patch(patch: TSpecBodyPatchTenant): void {
    this.loading.present();
    this._tenantService.patch(patch);
  }

  protected onCompanyInput({
    detail: { value }
  }: CustomEvent<{ value: string }>) {
    this.companyName = value;
    this._patch({ name: value });
  }

  protected onLoginRedirectsToChange({
    detail: { value }
  }: CustomEvent<{ value: string }>) {
    if (value) {
      this.loginRedirectsTo = value;
      this._patch({ loginRedirectsTo: value });
    }
  }

  protected async addDepartment() {
    const { role, data } = await showModal<{
      name: string;
    }>(
      {
        component: DepartmentModalComponent
      },
      this._modalCtrl
    );

    if (role === 'confirm') {
      this.loading.present();
      this._departmentApiService.addDepartment({ body: data }).subscribe({
        next: (department) => {
          this.loading.dismiss();
          this.departments.push(department);
        },
        error: (e) => {
          this.loading.dismiss();
          this._toastService.presentToast(
            e.error?.message || 'Unable to create department'
          );
        }
      });
    }
  }

  protected async updateDepartment(department: MongoStoredObject<IDepartment>) {
    const { role, data } = await showModal<{
      name: string;
    }>(
      {
        component: DepartmentModalComponent,
        props: { department }
      },
      this._modalCtrl
    );

    if (role === 'confirm') {
      this.loading.present();
      this._departmentApiService
        .updateDepartment({
          path: { departmentId: department._id },
          body: data
        })
        .subscribe({
          next: (department) => {
            this.loading.dismiss();
            this.departments = this.departments.map((p) =>
              department._id === p._id ? department : p
            );
          },
          error: (e) => {
            this.loading.dismiss();
            this._toastService.presentToast(
              e.error?.message || 'Unable to update department'
            );
          }
        });
    } else if (role === 'delete') {
      this.loading.present();
      this._departmentApiService
        .deleteDepartment({
          path: { departmentId: department._id }
        })
        .subscribe({
          next: () => {
            this.loading.dismiss();
            this.departments = this.departments.filter(
              (dep) => dep._id !== department._id
            );
          },
          error: (e) => {
            this.loading.dismiss();
            this._toastService.presentToast(
              e.error?.message || 'Unable to delete department'
            );
          }
        });
    }
  }

  protected trackDepartments(_: number, item: MongoStoredObject<IDepartment>) {
    return item._id;
  }

  protected async selectTimezone() {
    const { role, data } = await showModal<ITimezone>(
      {
        component: TimezoneSelectorModalComponent,
        props: {
          timezones: this.timezones,
          initialTzCode: this.timezone
        }
      },
      this._modalCtrl
    );

    if (role === 'confirm') {
      this.timezone = data.tzCode;
      this._patch({ timezone: data.tzCode });
    }
  }
}
