import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import {
  IonNav,
  IonPopover,
  ItemReorderCustomEvent,
  LoadingController,
  ViewDidEnter,
  ModalController
} from '@ionic/angular';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  map,
  shareReplay,
  take
} from 'rxjs';
import { TaskListService } from '@app/core/service/task-list.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { TaskCreateComponent } from './components/task-create/task-create.component';
import { TaskFolderCreateComponent } from './components/task-folder-create/task-folder-create.component';
import { ChecklistItemPickupComponent } from '@app/dashboard/dashboard/checklist-item-pickup/checklist-item-pickup.component';
import { TaskDto } from '@app/types/task-dto';
import { ChecklistItem } from '@app/types/checklist-item';
import { createChecklistItemId } from '@app/utils/create-checklist-item-id';
import { AuthenticationService } from '@app/core';
import { ToastService } from '@app/core/service/toast.service';
import { TaskFolderListService } from '@app/core/service/task-folder-list.service';
import { ITaskFolder } from '@backend/models/types/task-folder';
import { UserRoles } from '@backend/models/types/user';
import { showTaskSelectorModal } from '@app/utils/modal';
import { reorderItems } from '@app/utils/reorder';

interface TaskFilter {
  search: string;
  showIsDeleted: boolean;
}

const NO_FILTER = { search: '', showIsDeleted: false };

@Component({
  selector: 'app-task-management',
  templateUrl: './task-management.component.html',
  styleUrls: ['./task-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskManagementComponent implements ViewDidEnter {
  @ViewChild('popover')
  public popover: IonPopover;

  protected isAdmin = false;
  protected searchBarValue = '';
  protected readonly filter$ = new BehaviorSubject<TaskFilter>(NO_FILTER);
  protected reorderEnabled = false;
  protected reorderInProgress = false;
  protected isPopoverOpen = false;
  protected readonly taskList$: Observable<MongoStoredObject<TaskDto>[]> =
    combineLatest([this._taskListService.list$, this.filter$]).pipe(
      map(([tasks, filter]) => {
        return tasks.filter((task) => {
          if (task.isDeleted && !filter.showIsDeleted) return false;

          const search = filter.search.trim().toLowerCase();
          if (search === '') {
            return true;
          }
          const text = `${task.title} ${task.details.description ?? ''}`
            .trim()
            .toLowerCase();
          return text.includes(search);
        });
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  protected readonly taskFolders$: Observable<
    (MongoStoredObject<ITaskFolder> & { tasks: MongoStoredObject<TaskDto>[] })[]
  > = combineLatest([
    this._taskFolderListService.taskFolders$,
    this.taskList$,
    this.filter$
  ]).pipe(
    map(([folders, tasks, filter]) => {
      return folders
        .map((folder) => {
          return {
            ...folder,
            tasks: [
              ...tasks.filter((task) => task.taskFolder === folder._id)
            ].sort((a, b) => {
              return a.orderIndex - b.orderIndex;
            })
          };
        })
        .filter((folder) => {
          const search = filter.search.trim().toLowerCase();
          if (search === '') {
            return true;
          }
          const text = folder.title.trim().toLowerCase();
          return text.includes(search) || folder.tasks.length;
        });
    }),
    map((folders) =>
      [...folders].sort((a, b) => {
        return a.orderIndex - b.orderIndex;
      })
    ),
    shareReplay({ bufferSize: 1, refCount: true })
  );
  protected readonly defaultFolder$: Observable<{
    _id: string | null;
    title: string;
    tasks: MongoStoredObject<TaskDto>[];
  }> = combineLatest([this.taskList$]).pipe(
    map(([tasks]) => {
      return {
        _id: null,
        title: 'Ungrouped',
        tasks: [...tasks.filter((task) => !task.taskFolder)].sort((a, b) => {
          return a.orderIndex - b.orderIndex;
        })
      };
    }),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  public constructor(
    private readonly _toastService: ToastService,
    private readonly _taskListService: TaskListService,
    private readonly _nav: IonNav,
    private readonly _loadingCtrl: LoadingController,
    private readonly _authenticationService: AuthenticationService,
    private readonly _taskFolderListService: TaskFolderListService,
    private readonly _modalCtrl: ModalController
  ) {
    this.isAdmin = this._authenticationService.user
      ? this._authenticationService.user.roles.includes(UserRoles.Admin)
      : false;
  }

  public async ionViewDidEnter() {
    const topLoading = await this._loadingCtrl.getTop();
    if (topLoading) {
      topLoading.dismiss();
    }
  }

  protected onOpenTaskClick(task: MongoStoredObject<TaskDto>): void {
    if (this.reorderEnabled) {
      return;
    }

    this._nav.push(ChecklistItemPickupComponent, {
      item: new ChecklistItem(
        createChecklistItemId(task._id, new Date()),
        task,
        {
          start: new Date(),
          end: new Date()
        }
      ),
      addToChecklistMode: true
    });
  }

  protected onEditFolderClick(
    taskFolder: MongoStoredObject<ITaskFolder>
  ): void {
    if (this.reorderEnabled) {
      return;
    }

    this._nav.push(TaskFolderCreateComponent, {
      taskFolder
    });
  }

  protected onSearchChange(event: any): void {
    const {
      detail: { value }
    } = event;
    this.searchBarValue = value;
    this.filter$.next({
      search: value,
      showIsDeleted: this.filter$.value.showIsDeleted
    });
  }

  protected handleShowIsDeletedChange(event: any): void {
    this.filter$.next({
      search: this.filter$.value.search,
      showIsDeleted: event.detail.checked
    });
  }

  protected onBack(): void {
    this._nav.pop();
  }

  protected onReorderEnableClick(): void {
    this.reorderEnabled = !this.reorderEnabled;

    if (this.reorderEnabled) {
      this.searchBarValue = '';
      this.filter$.next(NO_FILTER);
    }
  }

  protected handleFolderReorder(event: ItemReorderCustomEvent): void {
    event.detail.complete();

    if (event.target.classList.contains('groups-list-reorderer')) {
      this.taskFolders$.pipe(take(1)).subscribe((taskFolders) => {
        const taskFoldersToReorder = taskFolders.map(
          (taskFolder) => taskFolder._id
        );
        reorderItems(taskFoldersToReorder, event.detail.from, event.detail.to);

        const indexes = taskFoldersToReorder.map((taskFolderId, index) => ({
          taskFolderId,
          orderIndex: index
        }));

        this._taskFolderListService
          .reorderTaskFoldersMultiple(indexes)
          .subscribe();
      });
    }
  }

  protected handleTaskReorder(
    event: ItemReorderCustomEvent,
    taskFolder: {
      _id: string | null;
      title: string;
      tasks: MongoStoredObject<TaskDto>[];
    }
  ): void {
    event.detail.complete();

    if (event.target.classList.contains('tasks-list-reorderer')) {
      const tasksToReorder = taskFolder.tasks.map((task) => task._id);
      reorderItems(tasksToReorder, event.detail.from, event.detail.to);

      const indexes = tasksToReorder.map((taskId, index) => ({
        taskId,
        orderIndex: index
      }));

      this._taskListService.reorderTasksMultiple([
        { taskFolder: taskFolder._id, tasks: indexes }
      ]);
    }
  }

  protected taskTrackBy(_index: number, item: MongoStoredObject<TaskDto>) {
    return item._id;
  }

  protected folderTrackBy(
    _index: number,
    item: MongoStoredObject<ITaskFolder>
  ) {
    return item._id;
  }

  public onCreateTaskPressed(): void {
    if (this.reorderEnabled) {
      return;
    }
    this._nav.push(TaskCreateComponent, {});
  }

  public onCreateFolderPressed(): void {
    if (this.reorderEnabled) {
      return;
    }
    this._nav.push(TaskFolderCreateComponent, {});
  }

  protected onReorderDoneClick(): void {
    this.reorderEnabled = false;
  }

  protected onEditButtonClick(e: Event): void {
    if (this.isAdmin) {
      this.popover.event = e;
      this.isPopoverOpen = true;
    } else {
      this._toastService.presentToast(
        'You do not have permission to add or rearrange tasks'
      );
    }
  }

  protected onPopoverDismiss(): void {
    this.isPopoverOpen = false;
  }

  protected selectTasksForGroup(folder: {
    _id: string | null;
    title: string;
    tasks: MongoStoredObject<TaskDto>[];
  }) {
    this.taskList$.pipe(take(1)).subscribe(async (allTasks) => {
      const { success, selectedTasks } = await showTaskSelectorModal(
        {
          title: folder.title,
          tasks: allTasks,
          selectedTasks: folder.tasks.map((t) => t._id),
          multiple: true
        },
        this._modalCtrl
      );

      if (success) {
        const removeFromGroup = folder.tasks
          .filter((t) => !selectedTasks.includes(t._id))
          .map((t) => t._id);

        this._taskListService.refoldTasksMultiple([
          { taskFolder: null, tasks: removeFromGroup },
          { taskFolder: folder._id, tasks: selectedTasks }
        ]);
      }
    });
  }
}
