import { Component, OnInit, Input } from '@angular/core';
import { AuthenticationService } from '@app/core';
import { UserModel } from '@app/core/model/user.model';
import { ObjectId } from '@app/types/object-id';
import {
  ActionSheetController,
  AlertController,
  IonNav,
  LoadingController,
  ModalController,
  ViewDidEnter,
  ViewWillLeave
} from '@ionic/angular';
import { isSameSet } from '@app/utils/is-same-set';
import { AnnouncementModel } from '@app/core/model/announcement.model';
import { AnnouncementListService } from '@app/core/service/announcement-list.service';
import { ToastService } from '@app/core/service/toast.service';
import { UploadFileApiService } from '@app/core/service/api/uploadFile.api.service';
import { formatUploadedBody } from '@app/utils/wysiwyg-editor';
import { EnvironmentService } from '@app/core/service/environment.service';
import { TenantService } from '@app/core/service/tenant.service';
import {
  disableBackGesture,
  enableBackGesture
} from '@app/utils/disable-back-gesture';
import { showUserSelectorModal } from '@app/utils/modal';

@Component({
  selector: 'app-announcement-add',
  templateUrl: './announcement-add.component.html'
})
export class AnnouncementAddComponent
  implements OnInit, ViewDidEnter, ViewWillLeave
{
  @Input()
  public users: UserModel[];

  @Input()
  public announcement: AnnouncementModel | null = null;

  protected serverUrl = '';
  protected subject = '';
  protected body = '';
  protected isCritical = true;
  protected user: UserModel | null;
  protected allUsers: UserModel[];
  protected selectedUserIds: ObjectId[] = [];
  protected disableAttachments = false;

  public constructor(
    private readonly _authenticationService: AuthenticationService,
    private readonly _toastService: ToastService,
    private readonly _ionNav: IonNav,
    private readonly _announcementList: AnnouncementListService,
    private readonly _alertCtrl: AlertController,
    private readonly _loadingCtrl: LoadingController,
    private readonly _uploadFileApiService: UploadFileApiService,
    private readonly _environmentService: EnvironmentService,
    private readonly _tenantService: TenantService,
    private readonly _actionSheetCtrl: ActionSheetController,
    private readonly _modalCtrl: ModalController
  ) {
    this.serverUrl = this._environmentService.serverUrl;
    this.user = this._authenticationService.user;

    this._tenantService.tenant$.subscribe((tenant) => {
      this.disableAttachments = !tenant.features.announcementAttachments;
    });
  }

  public ngOnInit() {
    this.allUsers = this.users.filter(
      (user: any) =>
        !user.isDeleted &&
        user._id.toString() !== this._authenticationService.user._id.toString()
    );

    if (this.announcement) {
      this.subject = this.announcement.title;
      this.body = formatUploadedBody(
        this.announcement.body,
        this.announcement.attachments,
        this.serverUrl
      );
      this.isCritical = this.announcement.isCritical;
      this.selectedUserIds = this.announcement.recipients.map(
        ({ id }) => id as ObjectId
      );
    }
  }

  public ionViewDidEnter() {
    disableBackGesture();
  }

  public ionViewWillLeave() {
    enableBackGesture();
  }

  protected get formattedRecipients(): string {
    if (this.selectedUserIds.length === 0) {
      return '';
    } else if (this.selectedUserIds.length === 1) {
      const user = this.allUsers.find(
        (user) => this.selectedUserIds[0] === user._id
      );
      return user ? `${user.firstName} ${user.lastName}` : '';
    } else if (
      this.selectedUserIds.length > 1 &&
      this.selectedUserIds.length !== this.allUsers.length
    ) {
      return `${this.selectedUserIds.length} recipients`;
    } else if (this.selectedUserIds.length === this.allUsers.length) {
      return 'All Users';
    }
  }

  protected get canNextStep(): boolean {
    if (this.announcement) {
      return this._isFormValid() && this._hasChanges(this.announcement);
    }
    return this._isFormValid();
  }

  private _hasChanges(announcement: AnnouncementModel): boolean {
    return !(
      this.body.trim() ===
        formatUploadedBody(
          announcement.body,
          announcement.attachments,
          this.serverUrl
        ) &&
      this.subject.trim() === announcement.title &&
      this.isCritical === announcement.isCritical &&
      isSameSet(
        new Set(this.selectedUserIds),
        announcement.recipients.map(({ id }) => id as ObjectId)
      )
    );
  }

  private _isFormValid(): boolean {
    return (
      this.selectedUserIds.length > 0 &&
      this.subject.length > 0 &&
      this.body.length > 0
    );
  }

  protected async askPublish() {
    if (this.announcement) {
      const alert = await this._alertCtrl.create({
        cssClass: 'wide-alert',
        header: 'Reset Acknowledgements?',
        subHeader:
          'Should all recipients be required to re-read and re-acknowledge this announcement with these changes?',
        message:
          'Note: This will erase any acknowledgements from the previous version of this announcement.',
        buttons: [
          {
            role: 'confirm',
            text: 'Do not notify anyone. Keep prior acknowledgements.',
            cssClass: 'wide-alert-button'
          },
          {
            role: 'destructive',
            text: 'Yes. Notify all and erase prior acknowledgements.',
            cssClass: 'wide-alert-button'
          },
          {
            role: 'cancel',
            text: 'Cancel'
          }
        ]
      });
      alert.present();

      const { role } = await alert.onWillDismiss();

      if (role === 'confirm') {
        this._publish(true);
      } else if (role === 'destructive') {
        this._publish();
      }
    } else {
      this._publish();
    }
  }

  private async _publish(keepStats?: boolean) {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._uploadFileApiService
      .extractAttachmentsFromBody(
        this.body || '',
        this.announcement?.attachments || []
      )
      .subscribe({
        next: ({ body, newAttachments, keptAttachments }) => {
          if (this.announcement) {
            this._announcementList
              .editAnnouncement(this.announcement._id, {
                title: this.subject,
                body,
                isCritical: this.isCritical,
                recipients: this.selectedUserIds.map((id) => ({ id })),
                attachments: [
                  ...keptAttachments,
                  ...newAttachments.map((a) => a._id.toString())
                ],
                keepStats
              })
              .subscribe(() => {
                this._toastService.presentToast(
                  'Announcement saved successfully!'
                );
                this._ionNav.pop();
              });
          } else {
            this._announcementList
              .publishAnnouncement({
                title: this.subject,
                body,
                isCritical: this.isCritical,
                recipients: this.selectedUserIds.map((id) => ({ id })),
                attachments: [
                  ...keptAttachments,
                  ...newAttachments.map((a) => a._id.toString())
                ]
              })
              .subscribe(() => {
                this._toastService.presentToast(
                  'Announcement published successfully!'
                );
                this._ionNav.pop();
              });
          }
        },
        error: (e) => {
          console.log(e);
          loading.dismiss();
          this._toastService.presentToast('Unable to upload attachments');
        }
      });
  }

  protected async askGoBack() {
    if (
      this.announcement
        ? this._hasChanges(this.announcement)
        : this.body || this.subject || this.selectedUserIds.length
    ) {
      const actionSheet = await this._actionSheetCtrl.create({
        header: 'Unsaved content',
        subHeader:
          'Are you sure you want to discard your work and close the editor?',
        buttons: [
          {
            role: 'destructive',
            text: 'Discard and close'
          },
          {
            role: 'cancel',
            text: 'Continue editing'
          }
        ]
      });
      actionSheet.present();

      const { role } = await actionSheet.onWillDismiss();

      if (role === 'destructive') {
        this._ionNav.pop();
      }
    } else {
      this._ionNav.pop();
    }
  }

  protected async selectUsers() {
    const { success, selectedUsers } = await showUserSelectorModal(
      {
        users: this.allUsers,
        recipients: this.selectedUserIds,
        multiple: true
      },
      this._modalCtrl
    );

    if (success) {
      this.selectedUserIds = selectedUsers;
    }
  }
}
