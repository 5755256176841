import { Component, ViewChild } from '@angular/core';
import { LocalStorageService } from '@app/core';
import { UserModel } from '@app/core/model/user.model';
import { UserApiService } from '@app/core/service/api/user.api.service';
import { PasscodeTimeoutService } from '@app/core/service/passcode-timeout.service';
import { TenantService } from '@app/core/service/tenant.service';
import { UserListService } from '@app/core/service/user-list.service';
import { ObjectId } from '@app/types/object-id';
import { isSameSet } from '@app/utils/is-same-set';
import { showUserSelectorModal } from '@app/utils/modal';
import { ITenantFeatures } from '@backend/models/types/tenant';
import { TSpecBodyPatchTenant } from '@backend/types/generated/Tenants/patchTenant';
import { IonLoading, IonNav, ModalController } from '@ionic/angular';
import { map } from 'rxjs';
import { AllowedInsightsPipe } from './insights-allowed.pipe';

@Component({
  selector: 'app-security-settings',
  templateUrl: './security-settings.component.html'
})
export class SecuritySettingsComponent {
  @ViewChild('loading', { static: true })
  public loading!: IonLoading;

  protected tenantFeatures: Pick<
    ITenantFeatures,
    'announcements' | 'checklist' | 'tags' | 'insights' | 'multiUserMode'
  > = {
    announcements: false,
    checklist: false,
    tags: false,
    insights: false,
    multiUserMode: false
  };
  protected allUsers: UserModel[] = [];
  protected tenantId = '';
  protected passcodeRedirectsTo = '';
  protected passcodeTimeout: number;

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _tenantService: TenantService,
    private readonly _userListService: UserListService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _userApiService: UserApiService,
    private readonly _passcodeTimeoutService: PasscodeTimeoutService,
    private readonly _modalCtrl: ModalController
  ) {
    this._userListService.users$
      .pipe(map((list) => list.filter(({ isDeleted }) => !isDeleted)))
      .subscribe((allUsers) => {
        this.allUsers = allUsers;
      });

    this._tenantService.tenant$.subscribe((tenant) => {
      if (this.loading) {
        this.loading.dismiss();
      }

      this.tenantId = tenant._id;
      this.tenantFeatures = tenant.features;
      this.passcodeTimeout = tenant.passcodeTimeout;

      if (
        (tenant.passcodeRedirectsTo === '/i/checklist' &&
          !this.tenantFeatures.checklist) ||
        (tenant.passcodeRedirectsTo === '/i/tags' &&
          !this.tenantFeatures.tags) ||
        (tenant.passcodeRedirectsTo === '/i/announcements' &&
          !this.tenantFeatures.announcements)
      ) {
        this.passcodeRedirectsTo = '/i/recognition';
      } else {
        this.passcodeRedirectsTo = tenant.passcodeRedirectsTo;
      }
    });
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  private _patch(patch: TSpecBodyPatchTenant): void {
    this.loading.present();
    this._tenantService.patch(patch);
  }

  private _patchInsightsHidden(userIds: ObjectId[], value: boolean) {
    this.loading.present();

    this._userListService
      .patchUsers({
        userId: userIds,
        isInsightsHidden: value
      })
      .subscribe(() => {
        this.loading.dismiss();
      });
  }

  protected toggleMultiUserMode() {
    if (this.isMultiUserModeActive) {
      this.deactivateMultiUserMode();
    } else {
      this.activateMultiUserMode();
    }
  }

  protected async activateMultiUserMode() {
    this.loading.present();

    this._userApiService
      .getAllUsersWithPinCodes({ path: { tenantId: this.tenantId } })
      .subscribe(() => {
        this.loading.dismiss();
        this._passcodeTimeoutService.activateMultiUserMode(
          this.passcodeRedirectsTo,
          this.passcodeTimeout
        );
      });
  }

  protected deactivateMultiUserMode() {
    this._passcodeTimeoutService.deactivateMultiUserMode();
  }

  protected get isMultiUserModeActive(): boolean {
    return !!this._localStorageService.getItem('multiUserMode');
  }

  protected onPasscodeRedirectsToChange({
    detail: { value }
  }: CustomEvent<{ value: string }>) {
    if (value) {
      this.passcodeRedirectsTo = value;
      this._patch({ passcodeRedirectsTo: value });
    }
  }

  protected onPasscodeTimeoutChange({
    detail: { value }
  }: CustomEvent<{ value: number }>) {
    if (value) {
      this.passcodeTimeout = value;
      this._patch({ passcodeTimeout: value });
    }
  }

  protected async onSelectInsightsUsersClick() {
    const recipients = new AllowedInsightsPipe().transform(this.allUsers);

    const { success, selectedUsers } = await showUserSelectorModal(
      {
        users: this.allUsers,
        recipients,
        multiple: true,
        title: 'Allow List'
      },
      this._modalCtrl
    );

    if (success) {
      const prevSelection = this.allUsers
        .filter(({ isInsightsHidden }) => !isInsightsHidden)
        .map(({ _id }) => _id);
      const selectionSet = new Set(selectedUsers);
      if (isSameSet(selectionSet, prevSelection)) {
        return;
      }
      const prevSelectionSet = new Set(prevSelection);
      const newSelected = selectedUsers.filter((s) => !prevSelectionSet.has(s));
      const newRemoved = prevSelection.filter((s) => !selectionSet.has(s));
      if (newRemoved.length > 0) {
        this._patchInsightsHidden(newRemoved, true);
      }
      if (newSelected.length > 0) {
        this._patchInsightsHidden(newSelected, false);
      }
    }
  }
}
