<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title class="ion-text-capitalize">
      {{ entity ? entity.name : 'Create Behavior' }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="_isMobile; else content" color="light">
  <ng-container *ngTemplateOutlet="content" />
</ion-content>

<ng-template #content>
  <app-full-height-container [formGroup]="form" [isModal]="true">
    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          label="Name"
          labelPlacement="floating"
          formControlName="name"
          type="text"
        />
      </ion-item>
      <ion-item>
        <ion-textarea
          label="Description"
          formControlName="description"
          labelPlacement="floating"
          [autoGrow]="true"
          autocapitalize="on"
        />
      </ion-item>
    </ion-list>
    <ion-list [inset]="true">
      <ion-item>
        <ion-input
          label="Points"
          labelPlacement="floating"
          pattern="[1-9][0-9]*"
          formControlName="points"
          [maxlength]="6"
          inputmode="numeric"
          type="text"
          digitOnly
          placeholder="0"
        />
      </ion-item>
    </ion-list>
    <ion-list [inset]="true">
      <ion-item>
        <ion-select
          labelPlacement="floating"
          aria-label="Available To"
          interface="popover"
          label="Available To"
          formControlName="whoEligible"
          appHaptics
        >
          <ion-select-option value="Everyone"> Everyone </ion-select-option>
          <ion-select-option value="Admin+Manager">
            Only Managers and Admins
          </ion-select-option>
          <ion-select-option value="Admin"> Only Admins </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>
    <ion-list [inset]="true">
      <ion-item>
        <ion-toggle appHaptics formControlName="isValue">
          <ion-label> Value </ion-label>
        </ion-toggle>
      </ion-item>
    </ion-list>

    <div bottom class="modal-actions">
      <ion-button
        *ngIf="this.entity"
        color="danger"
        expand="block"
        (click)="onDeleteClick()"
        appHaptics
        class="ion-no-margin"
        fill="outline"
      >
        Delete
      </ion-button>
      <ion-button
        color="dark"
        expand="block"
        (click)="onSaveClick()"
        appHaptics
        [disabled]="!canSave"
        [disableHaptics]="!canSave"
        class="ion-no-margin"
      >
        {{ entity ? 'Save' : 'Create' }}
      </ion-button>
    </div>
  </app-full-height-container>
</ng-template>
