<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title>Global Settings</ion-title>
    <ion-buttons slot="start" class="ion-hide-lg-up">
      <app-slim-menu-button />
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-full-height-container [isFullHeight]="true" [formGroup]="mainFormGroup">
    <app-list-pre-line title="Super Admins" />
    <ion-list [inset]="true">
      <ion-item
        *ngFor="let user of superAdmins; let i = index"
        [lines]="i === superAdmins.length - 1 ? 'none' : 'full'"
        class="list-item list-item--indent"
      >
        {{ user.firstName }} {{ user.lastName }}
      </ion-item>
    </ion-list>

    <app-list-pre-line title="Instant Feedback" />
    <ion-list [inset]="true" formGroupName="instantFeedback">
      <ion-item>
        <ion-input
          label="Email"
          formControlName="email"
          labelPlacement="floating"
        />
      </ion-item>
      <ion-item>
        <ion-input
          type="text"
          placeholder="1"
          [maxlength]="6"
          inputmode="numeric"
          pattern="[1-9][0-9]*"
          digitOnly
          formControlName="inactivityTime"
          label="Inactivity time before the help button appears"
          labelPlacement="floating"
        />
      </ion-item>
    </ion-list>

    <div formGroupName="psychologicalSurvey">
      <app-list-pre-line title="Pulse" />
      <ion-list [inset]="true">
        <ion-item>
          <ion-select
            formControlName="currentSurvey"
            labelPlacement="floating"
            aria-label="Current Survey"
            interface="popover"
            label="Current Survey"
            appHaptics
          >
            <ion-select-option
              *ngFor="let survey of psychologicalSurveys"
              [value]="survey.survey._id"
            >
              {{ survey.survey.title }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-input
            label="Email for suggested options"
            formControlName="emailForSuggestedOptions"
            labelPlacement="floating"
          />
        </ion-item>
        <ion-item>
          <ion-select
            formControlName="showFeedbackBy"
            labelPlacement="floating"
            aria-label="Show survey feedback by"
            interface="popover"
            label="Show survey feedback by"
            appHaptics
          >
            <ion-select-option
              [value]="psychologicalSurveyShowFeedbackBy.feedbackByBehaviors"
            >
              Survey Behaviors
            </ion-select-option>
            <ion-select-option
              [value]="
                psychologicalSurveyShowFeedbackBy.feedbackByEvaluationOptions
              "
            >
              Evaluation Options
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>

      <app-list-pre-line title="Pulse Questions" />
      <ion-list [inset]="true">
        <ion-item
          class="list-item list-item--indent ion-text-capitalize"
          lines="none"
          *ngFor="let question of currentSurveyQuestions; let i = index"
        >
          {{ i + 1 }}.
          {{ question.type.replace('_', ' ').toLowerCase() }}
        </ion-item>
      </ion-list>
    </div>

    <app-list-pre-line title="Integrations" />
    <ion-list [inset]="true" formGroupName="integrations">
      <ion-item>
        <ion-input
          label="Email for GoHighLevel integration errors"
          formControlName="ghlOnErrorEmail"
          labelPlacement="floating"
        />
      </ion-item>
    </ion-list>

    <app-list-pre-line title="AI" />
    <ion-list [inset]="true" formGroupName="aiPrompts">
      <ion-item>
        <ion-textarea
          label="Suggestion Title"
          formControlName="suggestionTitle"
          labelPlacement="floating"
          autocapitalize="on"
          [spellcheck]="true"
          [autoGrow]="true"
        />
      </ion-item>
    </ion-list>

    <div bottom class="modal-actions">
      <ion-button
        color="dark"
        expand="block"
        [disabled]="!canSave"
        (click)="save()"
        appHaptics
        [disableHaptics]="!canSave"
        class="ion-no-margin"
      >
        Save
      </ion-button>
    </div>
  </app-full-height-container>
</ion-content>
