import { NgModule } from '@angular/core';
import { GlobalSettingsComponent } from './global-settings.component';
import { RouterModule, Routes } from '@angular/router';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlimMenuButtonComponent } from '@app/modules/slim-menu-button/slim-menu-button.component';
import { DirectivesModule } from '@app/modules/directives.module';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { DividerModule } from '@app/modules/divider/divider.module';
import { FullHeightContainerModule } from '@app/modules/full-height-container/full-height-container.module';
import { ListPreLineModule } from '@app/modules/list-pre-line/list-pre-line.module';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    data: {
      title: 'Global Settings',
      rootComponent: GlobalSettingsComponent
    }
  }
];

@NgModule({
  imports: [
    NavBaseComponent,
    CommonModule,
    RouterModule.forChild(ROUTES),
    IonicModule,
    FullHeightContainerModule,
    ReactiveFormsModule,
    FormsModule,
    SlimMenuButtonComponent,
    DirectivesModule,
    DigitOnlyModule,
    DividerModule,
    ListPreLineModule
  ],
  declarations: [GlobalSettingsComponent]
})
export class GlobalSettingsModule {}
