<form
  [formGroup]="mainFormGroup"
  (ngSubmit)="onFormSubmit()"
  class="layout-container"
>
  <div class="top-content">
    <ion-grid class="grid">
      <ion-row>
        <ion-col size="12">
          <ion-input
            label="Title"
            class="app-w100 input-with-background"
            formControlName="title"
            labelPlacement="floating"
            fill="solid"
            autocapitalize="on"
            [spellcheck]="true"
          />
        </ion-col>
      </ion-row>
      <ion-row *ngIf="!isAuditTask">
        <ion-col size="12">
          <ion-input
            label="Description"
            class="app-w100 input-with-background"
            [value]="body | wysiwygPreview"
            labelPlacement="floating"
            fill="solid"
            [readonly]="true"
            (click)="showDescriptionEditor()"
            appHaptics
          />
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-item
            class="app-w100 input-with-background task-folder-item"
            lines="none"
          >
            <ion-input
              label="Group"
              labelPlacement="floating"
              fill="solid"
              [value]="taskFolder?.title || ''"
              [readonly]="true"
              id="selectTaskFolder"
              appHaptics
            />
            <ion-button
              fill="clear"
              (click)="handleTaskFolderClearClick()"
              appHaptics
              *ngIf="taskFolder"
              class="ion-no-margin"
            >
              <ion-icon
                slot="icon-only"
                name="close-circle"
                class="input-icon"
              />
            </ion-button>
          </ion-item>
        </ion-col>
      </ion-row>

      <div [attr.data-hidden]="!tenantFeatures.auditTasks && !isAuditTask">
        <ion-row>
          <ion-col size="12">
            <app-divider>Success Criteria</app-divider>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-segment formControlName="isAuditTask">
              <ion-segment-button [value]="false">
                <ion-label>Task</ion-label>
              </ion-segment-button>
              <ion-segment-button [value]="true">
                <ion-label>Audit</ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-col>
        </ion-row>
        <div [attr.data-hidden]="isAuditTask">
          <ion-row>
            <ion-col size="12">
              <ion-list>
                <ion-reorder-group
                  [disabled]="false"
                  (ionItemReorder)="handleAuditSuccessCriteriaReorder($event)"
                >
                  <ion-item
                    [button]="false"
                    *ngFor="
                      let criterion of selectedAuditSuccessCriteria;
                      let i = index;
                      trackBy: auditSuccessCriterionTrackBy
                    "
                    [detail]="false"
                    class="list-item opaque-reorder-item"
                    [lines]="
                      i === selectedAuditSuccessCriteria.length - 1
                        ? 'none'
                        : 'full'
                    "
                  >
                    <ion-input
                      [value]="criterion"
                      (ionChange)="onAuditSuccessCriterionChange(i, $event)"
                      [placeholder]="'Success Criterion ' + (i + 1)"
                      [spellcheck]="true"
                    />
                    <ion-button
                      (click)="removeAuditSuccessCriterionAtIndex(i)"
                      fill="clear"
                      color="danger"
                      appHaptics
                    >
                      <ion-icon slot="icon-only" name="close-circle" />
                    </ion-button>
                    <ion-reorder slot="end">
                      <ion-icon
                        class="reorder-handle-icon"
                        name="reorder-two-outline"
                      />
                    </ion-reorder>
                  </ion-item>
                </ion-reorder-group>
              </ion-list>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <ion-button
                color="light"
                expand="block"
                appHaptics
                class="ion-no-margin"
                (click)="addEmptyAuditSuccessCriterion()"
              >
                Add Success Criterion
              </ion-button>
            </ion-col>
          </ion-row>
        </div>
        <div [attr.data-hidden]="!isAuditTask">
          <ion-row>
            <ion-col size="12">
              <ion-list>
                <ion-reorder-group
                  [disabled]="false"
                  (ionItemReorder)="handleTasksForAuditReorder($any($event))"
                >
                  <ion-item
                    [button]="false"
                    *ngFor="
                      let task of selectedTasksForAudit;
                      let i = index;
                      trackBy: taskForAuditTrackBy
                    "
                    [detail]="false"
                    class="list-item opaque-reorder-item"
                    [lines]="
                      i === selectedTasksForAudit.length - 1 ? 'none' : 'full'
                    "
                  >
                    <ion-label class="ion-text-capitalize">
                      {{ task.title }}
                      (Criteria: {{ task.auditSuccessCriteria.length }})
                    </ion-label>
                    <ion-reorder slot="end">
                      <ion-icon
                        class="reorder-handle-icon"
                        name="reorder-two-outline"
                      />
                    </ion-reorder>
                  </ion-item>
                </ion-reorder-group>
              </ion-list>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <ion-button
                color="light"
                expand="block"
                (click)="selectTasksForAudit()"
                appHaptics
                class="ion-no-margin"
              >
                Select Tasks for Audit
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <ion-button
                color="light"
                expand="block"
                id="selectAuditReviewers"
                appHaptics
                class="ion-no-margin"
              >
                <ng-container
                  [ngPlural]="
                    mainFormGroup.controls.auditReviewers.value.length
                  "
                >
                  <ng-template ngPluralCase="0">
                    Select Audit Reviewers
                  </ng-template>
                  <ng-template ngPluralCase="=1">1 audit reviewer</ng-template>
                  <ng-template ngPluralCase="other">
                    {{ mainFormGroup.controls.auditReviewers.value.length }}
                    audit reviewers
                  </ng-template>
                </ng-container>
              </ion-button>
            </ion-col>
          </ion-row>
        </div>
      </div>

      <ion-row>
        <ion-col size="12">
          <app-divider>
            How many times can this task be completed per day?
          </app-divider>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-segment
            [value]="pointsEarns"
            (ionChange)="onPointEarnsChange($event)"
          >
            <ion-segment-button [value]="taskPointsEarns.PER_COMPLETION">
              <ion-label>Once</ion-label>
            </ion-segment-button>
            <ion-segment-button [value]="taskPointsEarns.PER_UNIT">
              <ion-label>Multiple</ion-label>
            </ion-segment-button>
          </ion-segment>
        </ion-col>
      </ion-row>

      <ion-row
        *ngIf="
          mainFormGroup.controls.pointsEarns.value ===
          taskPointsEarns.PER_COMPLETION
        "
      >
        <ion-col size="12">
          <div class="inline-heading">Complete the following sentences:</div>
          <div class="inline-inputs">
            User will earn
            <app-inline-input
              formControlName="points"
              placeholder="4"
              appendText="point"
              appendTextPlural="points"
              type="number"
            />
            once they
            <div class="inline-group">
              <app-inline-input
                formControlName="verbBase"
                placeholder="take out"
              />
              <app-inline-input
                formControlName="pointsUnit"
                placeholder="the trash"
              />.
            </div>
          </div>
          <div class="inline-inputs">
            {{ userFirstName }}
            <app-inline-input
              formControlName="verbPastSimple"
              placeholder="took out"
              fontStyle="italic"
            />
            {{ pointsUnit || 'the trash' }}.
          </div>
        </ion-col>
      </ion-row>

      <ion-row
        *ngIf="
          mainFormGroup.controls.pointsEarns.value === taskPointsEarns.PER_UNIT
        "
      >
        <ion-col size="12">
          <div class="inline-heading">Complete the following sentences:</div>
          <div class="inline-inputs">
            Users earn
            <app-inline-input
              formControlName="points"
              placeholder="4"
              appendText="point"
              appendTextPlural="points"
              type="number"
            />
            for every
            <div class="inline-group">
              <app-inline-input
                formControlName="pointsUnit"
                placeholder="trash bag"
              />
              they’ve
              <app-inline-input
                formControlName="verbPastParticiple"
                placeholder="thrown out"
              />.
            </div>
          </div>

          <div class="inline-inputs">
            How many
            <app-inline-input
              formControlName="nounPlural"
              placeholder="trash bags"
            />
            did you
            <app-inline-input
              formControlName="verbBase"
              placeholder="throw out"
            />?
          </div>
          <div class="inline-inputs">
            {{ userFirstName }}
            <app-inline-input
              formControlName="verbPastSimple"
              placeholder="threw out"
            />
            3
            {{ nounPlural || 'trash bags' }}.
          </div>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <app-divider>When does this task repeat?</app-divider>
        </ion-col>
      </ion-row>
      <ion-row *ngFor="let schedule of schedules.controls">
        <ion-col size="12" class="no-padding">
          <app-schedule-subform [form]="schedule" />
        </ion-col>
      </ion-row>
      <ng-container
        *ngIf="scheduleType !== null && scheduleType !== taskScheduleType.DAILY"
      >
        <ion-row>
          <ion-col size="12">
            <app-divider>When is this task visible?</app-divider>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-checkbox
              formControlName="shouldReappear"
              labelPlacement="end"
              class="semi-bold"
              appHaptics
            >
              Should reappear every day until completed
            </ion-checkbox>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="app-notice">
            If a task is not completed by the time it is scheduled to repeat,
            the uncompleted instance will be replaced by the new instance. This
            allows us to keep track of how often a task is not completed.
          </ion-col>
        </ion-row>
      </ng-container>
      <ion-row
        [attr.data-hidden]="!allowTasksToBeDisplayedOnlyDuringCertainHours"
      >
        <ion-col size="4">
          <app-time-form-control
            class="app-w100"
            formControlName="appearsAt"
            label="Task appears at"
          />
        </ion-col>
        <ion-col size="4" class="ion-text-center duration">
          <div class="duration-title">Duration</div>
          <div
            class="duration-value"
            *ngIf="
              mainFormGroup.controls.appearsAt.value !== null &&
                mainFormGroup.controls.disappearsAt.value !== null;
              else undefinedDuration
            "
            [innerHtml]="
              mainFormGroup.controls.appearsAt.value
                | appDuration : mainFormGroup.controls.disappearsAt.value
            "
          ></div>
        </ion-col>
        <ion-col size="4">
          <app-time-form-control
            class="app-w100"
            formControlName="disappearsAt"
            label="Task disappears at"
          />
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <app-divider>Performance</app-divider>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-select
            formControlName="performanceEvaluation"
            labelPlacement="floating"
            fill="solid"
            aria-label="Task speed"
            interface="popover"
            label="Evaluate task speed against"
            class="app-w100 input-with-background select-round-fix"
            appHaptics
          >
            <ion-select-option [value]="taskPerformanceEvaluationType.STANDARD">
              Fixed Standard
            </ion-select-option>
            <ion-select-option [value]="taskPerformanceEvaluationType.AVERAGE">
              Average Performance
            </ion-select-option>
          </ion-select>
        </ion-col>
      </ion-row>
      <ion-row
        *ngIf="performanceEvaluation === taskPerformanceEvaluationType.STANDARD"
      >
        <ion-col size="12">
          <app-performance-duration-form-control
            class="app-w100"
            formControlName="performanceDuration"
            label="Expected duration"
          />
        </ion-col>
      </ion-row>

      <div [ngClass]="{ 'disabled-feature': !tenantFeatures.timeAttack }">
        <ion-row>
          <ion-col size="12">
            <app-divider>Time Attack</app-divider>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-select
              formControlName="timeAttack"
              labelPlacement="floating"
              fill="solid"
              aria-label="Time Attack"
              interface="popover"
              label="Time Attack"
              class="app-w100 input-with-background select-round-fix"
              appHaptics
              [disableHaptics]="!tenantFeatures.timeAttack"
            >
              <ion-select-option
                [value]="taskTimeAttackType.OFF"
                [disabled]="!tenantFeatures.timeAttack"
              >
                Off
              </ion-select-option>
              <ion-select-option
                [value]="taskTimeAttackType.VOLUNTARY"
                [disabled]="!tenantFeatures.timeAttack"
              >
                Voluntary
              </ion-select-option>
              <ion-select-option
                [value]="taskTimeAttackType.REQUIRED"
                [disabled]="!tenantFeatures.timeAttack"
              >
                Required
              </ion-select-option>
            </ion-select>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="timeAttack !== taskTimeAttackType.OFF">
          <ion-col size="12">
            <div class="inline-inputs">
              User will earn
              <app-inline-input
                formControlName="timeAttackRewardPoints"
                placeholder="0"
                appendText="point"
                appendTextPlural="points"
                type="number"
                [disabled]="!tenantFeatures.timeAttack"
              />
              for beating the average while maintaining quality
            </div>
            <div class="inline-inputs">
              User will earn
              <app-inline-input
                formControlName="timeAttackAttemptPoints"
                placeholder="0"
                appendText="point"
                appendTextPlural="points"
                type="number"
                [disabled]="!tenantFeatures.timeAttack"
              />
              for attempting a time attack
            </div>
          </ion-col>
        </ion-row>
      </div>

      <ion-row>
        <ion-col size="12">
          <app-divider>Who is allowed to perform this task</app-divider>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-button
            (click)="openSkillMatrix()"
            expand="block"
            color="light"
            class="ion-no-margin"
          >
            <ion-icon slot="start" name="grid-outline" />
            <ion-label>Training</ion-label>
          </ion-button>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <app-divider>Notification upon completion</app-divider>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="app-notice" size="12">
          Notify selected users upon task completion
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-button
            color="light"
            expand="block"
            id="selectNotifyUsersUponCompletion"
            appHaptics
            class="ion-no-margin"
          >
            <ng-container
              *ngIf="
                mainFormGroup.controls.notifyEveryoneUponCompletion.value;
                else selectedNotifyUsersUponCompletionUsersCaption
              "
            >
              All Users
            </ng-container>
            <ng-template #selectedNotifyUsersUponCompletionUsersCaption>
              <ng-container
                [ngPlural]="
                  mainFormGroup.controls.notifyUsersUponCompletion.value.length
                "
              >
                <ng-template ngPluralCase="0"> Select Users </ng-template>
                <ng-template ngPluralCase="=1">1 user</ng-template>
                <ng-template ngPluralCase="other">
                  {{
                    mainFormGroup.controls.notifyUsersUponCompletion.value
                      .length
                  }}
                  users
                </ng-template>
              </ng-container>
            </ng-template>
          </ion-button>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <app-divider>Severity</app-divider>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-checkbox
            class="semi-bold"
            formControlName="isCritical"
            labelPlacement="end"
            appHaptics
          >
            Is this task critical?
          </ion-checkbox>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="app-notice" size="12">
          Critical tasks show up with a gold marker to help staff quickly
          identify high-priority tasks which must always be completed.
        </ion-col>
      </ion-row>

      <div [attr.data-hidden]="!tenantFeatures.actionsUponTaskCompletion">
        <ion-row>
          <ion-col size="12">
            <app-divider>Actions To Trigger Upon Completion</app-divider>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-checkbox
              class="semi-bold"
              [checked]="!!requestProcessFeedback"
              (ionChange)="onRequestProcessFeedbackToggle()"
              labelPlacement="end"
              appHaptics
            >
              Request Process Feedback
            </ion-checkbox>
          </ion-col>
          <ion-row>
            <ion-col class="app-notice" size="12">
              When enabled, user is prompted with a question about the process
              itself
            </ion-col>
          </ion-row>
        </ion-row>
        <div *ngIf="requestProcessFeedback">
          <ion-row>
            <ion-col size="12">
              <div class="field-group">
                <ion-row>
                  <ion-col size="12">
                    <div class="app-notice">
                      How many times must the user complete the task before
                      being asked to provide feedback again?
                    </div>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="12">
                    <ion-input
                      class="app-w100 input-with-background"
                      type="text"
                      placeholder="1"
                      [maxlength]="6"
                      inputmode="numeric"
                      pattern="[1-9][0-9]*"
                      digitOnly
                      fill="solid"
                      [value]="requestProcessFeedback.completeThreshold"
                      (ionChange)="
                        onActionUponCompletingChange(
                          actionType.REQUEST_PROCESS_FEEDBACK,
                          'completeThreshold',
                          'value',
                          $event
                        )
                      "
                    />
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="12">
                    <div class="app-notice">
                      How many days must pass before the user can be asked to
                      provide feedback again?
                    </div>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="12">
                    <ion-input
                      class="app-w100 input-with-background"
                      type="text"
                      placeholder="1"
                      [maxlength]="6"
                      inputmode="numeric"
                      pattern="[1-9][0-9]*"
                      digitOnly
                      fill="solid"
                      [value]="requestProcessFeedback.delayInDays"
                      (ionChange)="
                        onActionUponCompletingChange(
                          actionType.REQUEST_PROCESS_FEEDBACK,
                          'delayInDays',
                          'value',
                          $event
                        )
                      "
                    />
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="12">
                    <ion-checkbox
                      class="semi-bold"
                      labelPlacement="end"
                      appHaptics
                      [checked]="requestProcessFeedback.mandatory"
                      (ionChange)="
                        onActionUponCompletingChange(
                          actionType.REQUEST_PROCESS_FEEDBACK,
                          'mandatory',
                          'checked',
                          $event
                        )
                      "
                    >
                      Make feedback mandatory
                    </ion-checkbox>
                  </ion-col>
                </ion-row>
              </div>
            </ion-col>
          </ion-row>
        </div>
        <ion-row>
          <ion-col size="12">
            <ion-checkbox
              class="semi-bold"
              [checked]="!!promptUserToLeaveNote"
              (ionChange)="onPromptUserToLeaveNoteToggle()"
              labelPlacement="end"
              appHaptics
            >
              Prompt User to Leave a Note
            </ion-checkbox>
          </ion-col>
          <ion-row>
            <ion-col class="app-notice" size="12">
              When enabled, user is prompted to leave a note for the next person
              to see when they click on the task
            </ion-col>
          </ion-row>
        </ion-row>
        <div [attr.data-hidden]="!promptUserToLeaveNote">
          <ion-row>
            <ion-col size="12">
              <div class="field-group">
                <ion-row>
                  <ion-col size="12">
                    <div class="app-notice">
                      Who can see notes for this task?
                    </div>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="12">
                    <ion-button
                      color="light"
                      expand="block"
                      id="selectUsersWhoCanSeeNotes"
                      appHaptics
                      class="ion-no-margin"
                    >
                      <ng-container *ngIf="promptUserToLeaveNote">
                        <ng-container
                          *ngIf="
                            promptUserToLeaveNote.visibleToEveryone;
                            else selectedUsersCaption2
                          "
                        >
                          All Users
                        </ng-container>
                        <ng-template #selectedUsersCaption2>
                          <ng-container
                            [ngPlural]="
                              promptUserToLeaveNote.visibleToUsers.length
                            "
                          >
                            <ng-template ngPluralCase="0">
                              <span class="warning-caption">Select Users</span>
                            </ng-template>
                            <ng-template ngPluralCase="=1">1 user</ng-template>
                            <ng-template ngPluralCase="other">
                              {{ promptUserToLeaveNote.visibleToUsers.length }}
                              users
                            </ng-template>
                          </ng-container>
                        </ng-template>
                      </ng-container>
                    </ion-button>
                  </ion-col>
                </ion-row>
                <ion-row *ngIf="promptUserToLeaveNote">
                  <ion-col size="12">
                    <ion-checkbox
                      class="semi-bold"
                      labelPlacement="end"
                      appHaptics
                      [checked]="promptUserToLeaveNote.mandatory"
                      (ionChange)="
                        onActionUponCompletingChange(
                          actionType.PROMPT_USER_TO_LEAVE_NOTE,
                          'mandatory',
                          'checked',
                          $event
                        )
                      "
                    >
                      Make Notes Mandatory for this task
                    </ion-checkbox>
                  </ion-col>
                </ion-row>
                <ion-row *ngIf="promptUserToLeaveNote">
                  <ion-col size="12">
                    <ion-select
                      fill="solid"
                      aria-label="Who can see notes for this task?"
                      interface="popover"
                      appHaptics
                      class="app-w100 input-with-background select-round-fix"
                      labelPlacement="floating"
                      label="Show the most recent note"
                      [value]="promptUserToLeaveNote.showMoreRecentNote"
                      (ionChange)="
                        onActionUponCompletingChange(
                          actionType.PROMPT_USER_TO_LEAVE_NOTE,
                          'showMoreRecentNote',
                          'value',
                          $event
                        )
                      "
                    >
                      <ion-select-option
                        [value]="showMoreRecentNote.ONLY_ONCE_TO_EACH_USER"
                      >
                        Only once to each user
                      </ion-select-option>
                      <ion-select-option
                        [value]="
                          showMoreRecentNote.UNTIL_TASK_IS_COMPLETED_AGAIN_BY_ANYONE
                        "
                      >
                        Until this task is completed again by anyone
                      </ion-select-option>
                      <ion-select-option
                        [value]="showMoreRecentNote.EVERY_TIME_TASK_IS_OPENED"
                      >
                        Every time this task is opened
                      </ion-select-option>
                    </ion-select>
                  </ion-col>
                </ion-row>
              </div>
            </ion-col>
          </ion-row>
        </div>
      </div>
    </ion-grid>
  </div>

  <div class="bottom-content">
    <ion-grid>
      <ion-row class="ion-justify-content-center ion-padding">
        <ion-col size="4" *ngIf="task === null; else updateActions">
          <ion-button
            type="submit"
            color="dark"
            expand="block"
            [disabled]="mainFormGroup.invalid || mainFormGroup.pristine"
            appHaptics
            [disableHaptics]="mainFormGroup.invalid || mainFormGroup.pristine"
          >
            Publish
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</form>

<ng-template #undefinedDuration>
  <div class="duration-value">--:--</div>
</ng-template>

<ng-template #updateActions>
  <ion-col size="4">
    <ion-button
      *ngIf="!task.isDeleted"
      type="button"
      color="danger"
      expand="block"
      fill="outline"
      (click)="onDeleteClick()"
      appHaptics
    >
      Archive
    </ion-button>
    <ion-button
      *ngIf="task.isDeleted"
      type="button"
      color="dark"
      expand="block"
      fill="outline"
      (click)="onRestoreClick()"
      appHaptics
    >
      Unarchive
    </ion-button>
  </ion-col>
  <ion-col size="4">
    <ion-button
      type="submit"
      color="dark"
      expand="block"
      [disabled]="!hasChanges"
      appHaptics
      [disableHaptics]="!hasChanges"
    >
      Update
    </ion-button>
  </ion-col>
</ng-template>

<ion-modal
  trigger="selectNotifyUsersUponCompletion"
  #notifyUsersUponCompletionModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <ng-container *ngIf="allUsers$ | async as allUsers">
      <app-user-selector
        title="Notification upon completion"
        class="ion-page"
        [users]="allUsers"
        [recipients]="
          mainFormGroup.controls.notifyEveryoneUponCompletion.value
            ? (allUsers | pick : '_id')
            : notifyUsersUponCompletion
        "
        (selectionChange)="notifyUsersUponCompletionChanged($event, allUsers)"
        (selectionCancel)="notifyUsersUponCompletionModal.dismiss()"
        [multiple]="true"
      />
    </ng-container>
  </ng-template>
</ion-modal>

<ion-modal
  trigger="selectTaskFolder"
  #taskFolderSelectorModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-task-folder-selector
      class="ion-page"
      [taskFolders]="allTaskFolders"
      (selectionChange)="onTaskFolderSelect($event)"
      (selectionCancel)="taskFolderSelectorModal.dismiss()"
    />
  </ng-template>
</ion-modal>

<ion-modal
  trigger="selectUsersWhoCanSeeNotes"
  #selectUsersWhoCanSeeNotesModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <ng-container *ngIf="allUsers$ | async as allUsers">
      <app-user-selector
        title="Who can see notes for this task?"
        class="ion-page"
        [users]="allUsers"
        [recipients]="
          promptUserToLeaveNote.visibleToEveryone
            ? (allUsers | pick : '_id')
            : promptUserToLeaveNote.visibleToUsers
        "
        (selectionChange)="usersWhoCanSeeNotesChange($event, allUsers)"
        (selectionCancel)="selectUsersWhoCanSeeNotesModal.dismiss()"
        [multiple]="true"
      />
    </ng-container>
  </ng-template>
</ion-modal>

<ion-modal
  trigger="selectAuditReviewers"
  #selectAuditReviewersModals
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <ng-container *ngIf="allUsers$ | async as allUsers">
      <app-user-selector
        title="Audit Reviewers"
        class="ion-page"
        [users]="allUsers"
        [recipients]="mainFormGroup.controls.auditReviewers.value"
        (selectionChange)="auditReviewersChange($event)"
        (selectionCancel)="selectAuditReviewersModals.dismiss()"
        [multiple]="true"
        [hideSelectAllButton]="true"
      />
    </ng-container>
  </ng-template>
</ion-modal>
