import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BehaviorModel } from '../../model/behavior.model';
import {
  TSpecPathGetAllBehaviors,
  TSpecRoute_getAllBehaviors
} from '@backend/types/generated/Behaviors/getAllBehaviors';
import {
  TSpecPathGetNonSysGenBehaviors,
  TSpecRoute_getNonSysGenBehaviors
} from '@backend/types/generated/Behaviors/getNonSysGenBehaviors';
import {
  TSpecPathGetValueBehaviors,
  TSpecRoute_getValueBehaviors
} from '@backend/types/generated/Behaviors/getValueBehaviors';
import {
  TSpecPathGetBehavior,
  TSpecRoute_getBehavior
} from '@backend/types/generated/Behaviors/getBehavior';
import {
  TSpecBodyCreateBehavior,
  TSpecRoute_createBehavior
} from '@backend/types/generated/Behaviors/createBehavior';
import {
  TSpecBodyMoveBehavior,
  TSpecPathMoveBehavior,
  TSpecRoute_moveBehavior
} from '@backend/types/generated/Behaviors/moveBehavior';
import {
  TSpecPathDeleteBehavior,
  TSpecRoute_deleteBehavior
} from '@backend/types/generated/Behaviors/deleteBehavior';
import {
  TSpecBodyUpdateBehavior,
  TSpecPathUpdateBehavior,
  TSpecRoute_updateBehavior
} from '@backend/types/generated/Behaviors/updateBehavior';

@Injectable({
  providedIn: 'root'
})
export class BehaviorApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getAllBehaviors(params: {
    path: TSpecPathGetAllBehaviors;
  }): Observable<BehaviorModel[]> {
    return this._httpClient.get<BehaviorModel[]>(
      TSpecRoute_getAllBehaviors(params.path)
    );
  }

  public getNonSysGenBehaviors(params: {
    path: TSpecPathGetNonSysGenBehaviors;
  }): Observable<BehaviorModel[]> {
    return this._httpClient.get<BehaviorModel[]>(
      TSpecRoute_getNonSysGenBehaviors(params.path)
    );
  }

  public getValueBehaviors(params: {
    path: TSpecPathGetValueBehaviors;
  }): Observable<BehaviorModel[]> {
    return this._httpClient.get<BehaviorModel[]>(
      TSpecRoute_getValueBehaviors(params.path)
    );
  }

  public getBehavior(params: {
    path: TSpecPathGetBehavior;
  }): Observable<BehaviorModel> {
    return this._httpClient.get<BehaviorModel>(
      TSpecRoute_getBehavior(params.path)
    );
  }

  public createBehavior(params: {
    body: TSpecBodyCreateBehavior;
  }): Observable<BehaviorModel> {
    return this._httpClient.post<BehaviorModel>(
      TSpecRoute_createBehavior(),
      params.body
    );
  }

  public updateBehavior(params: {
    path: TSpecPathUpdateBehavior;
    body: TSpecBodyUpdateBehavior;
  }): Observable<BehaviorModel> {
    return this._httpClient.patch<BehaviorModel>(
      TSpecRoute_updateBehavior(params.path),
      params.body
    );
  }

  public deleteBehavior(params: {
    path: TSpecPathDeleteBehavior;
  }): Observable<null> {
    return this._httpClient.delete<null>(
      TSpecRoute_deleteBehavior(params.path)
    );
  }

  public moveBehavior(params: {
    body: TSpecBodyMoveBehavior;
  }): Observable<null> {
    return this._httpClient.post<null>(TSpecRoute_moveBehavior(), params.body);
  }
}
