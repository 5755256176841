import { Component, OnInit, ViewChild } from '@angular/core';
import { BehaviorApiService } from '@app/core/service/api/behavior.api.service';
import { TenantService } from '@app/core/service/tenant.service';
import {
  IonNav,
  ItemReorderCustomEvent,
  LoadingController,
  ModalController,
  IonLoading
} from '@ionic/angular';
import { ITenant } from '@backend/models/types/tenant';
import { BehaviorModel } from '@app/core/model/behavior.model';
import { TenantApiService } from '@app/core/service/api/tenant.api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { TaskDto } from '@app/types/task-dto';
import { combineLatest } from 'rxjs';
import {
  EditSystemEntityModalComponent,
  ISystemEntity
} from '@app/modals/edit-system-entity-modal/edit-system-entity-modal.component';
import { TaskListService } from '@app/core/service/task-list.service';
import { UserRoles } from '@backend/models/types/user';
import { showModal } from '@app/utils/modal';
import {
  EditNonSystemEntityModalComponent,
  INonSystemEntity
} from '@app/modals/edit-non-system-entity-modal/edit-non-system-entity-modal.component';
import { reorderItems } from '@app/utils/reorder';

@Component({
  selector: 'app-behaviors-edit',
  templateUrl: './behaviors-edit.component.html',
  styleUrls: ['./behaviors-edit.component.scss']
})
export class BehaviorsEditComponent implements OnInit {
  @ViewChild('loading', { static: true })
  public loading!: IonLoading;

  protected currentTab = 'behaviors';
  protected recognitionBehaviors: BehaviorModel[] = [];
  protected tenant: MongoStoredObject<ITenant>;
  protected sysGen: {
    behaviors: (BehaviorModel & {
      module?: { chipText: string; chipColor: string };
    })[];
    tasks: (MongoStoredObject<TaskDto> & {
      module?: { chipText: string; chipColor: string };
    })[];
  } = {
    behaviors: [],
    tasks: []
  };

  public constructor(
    private readonly _behaviorApiService: BehaviorApiService,
    private readonly _taskListService: TaskListService,
    private readonly _tenantService: TenantService,
    private readonly _tenantApiService: TenantApiService,
    private readonly _ionNav: IonNav,
    private readonly _modalCtrl: ModalController,
    private readonly _loadingCtrl: LoadingController
  ) {}

  public ngOnInit() {
    this._tenantService.tenant$.subscribe((tenant) => {
      this.tenant = tenant;
      this._getBehaviors();
    });
  }

  private _getBehaviors() {
    const findBehaviorModule = (
      item: BehaviorModel
    ): BehaviorModel & {
      module?: { chipText: string; chipColor: string };
    } => {
      const behaviorId = item._id.toString();
      switch (behaviorId) {
        case this.tenant.behaviors.checklist?.toString():
          return {
            ...item,
            module: { chipColor: 'success', chipText: 'Checklist' }
          };
        case this.tenant.behaviors.positiveFeedback?.toString():
          return {
            ...item,
            module: { chipColor: 'warning', chipText: 'Pulse' }
          };
        case this.tenant.behaviors.reinforce?.toString():
          return {
            ...item,
            module: { chipColor: 'danger', chipText: 'Recognition' }
          };
      }
      return item;
    };

    const findTaskModule = (
      item: MongoStoredObject<TaskDto>
    ): MongoStoredObject<TaskDto> & {
      module?: { chipText: string; chipColor: string };
    } => {
      const taskId = item._id.toString();
      switch (taskId) {
        case this.tenant.tasks.uploadTag?.toString():
        case this.tenant.tasks.servingTable?.toString():
        case this.tenant.tasks.readyForPickup?.toString():
        case this.tenant.tasks.completeTag?.toString():
        case this.tenant.tasks.reminder1st?.toString():
        case this.tenant.tasks.reminder2nd?.toString():
          return {
            ...item,
            module: { chipColor: 'tertiary', chipText: 'Tags' }
          };
        case this.tenant.tasks.dailySurvey?.toString():
          return {
            ...item,
            module: { chipColor: 'warning', chipText: 'Pulse' }
          };
        case this.tenant.tasks.suggestionSubmit?.toString():
          return {
            ...item,
            module: { chipColor: 'success', chipText: 'Suggestions' }
          };
      }
      return item;
    };

    this.loading.present();

    combineLatest([
      this._tenantApiService.getSysGenBehaviorsAndTasks(),
      this._behaviorApiService.getNonSysGenBehaviors({
        path: { tenantId: this.tenant._id }
      })
    ]).subscribe(([sysGen, recognitionBehaviors]) => {
      if (sysGen) {
        this.sysGen = {
          behaviors: sysGen.behaviors
            .map(findBehaviorModule)
            .filter(
              (m) =>
                (m.module?.chipText === 'Checklist' &&
                  this.tenant.features.checklist) ||
                (m.module?.chipText === 'Pulse' &&
                  this.tenant.features.survey) ||
                m.module?.chipText === 'Recognition'
            ),
          tasks: sysGen.tasks
            .map(findTaskModule)
            .filter(
              (m) =>
                (m.module?.chipText === 'Tags' && this.tenant.features.tags) ||
                (m.module?.chipText === 'Pulse' &&
                  this.tenant.features.survey) ||
                (m.module?.chipText === 'Suggestions' &&
                  this.tenant.features.suggestions)
            )
        };
      }

      if (recognitionBehaviors) {
        this.recognitionBehaviors = recognitionBehaviors;
      }

      this.loading.dismiss();
    });
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  protected onCurrentTabChange(event: any): void {
    this.currentTab = event.detail.value;
  }

  protected async onNonSysBehaviorAddClick() {
    const { role, data } = await showModal<INonSystemEntity>(
      { component: EditNonSystemEntityModalComponent },
      this._modalCtrl
    );

    if (role === 'confirm') {
      this.loading.present();

      let eligible: UserRoles[] = [];
      switch (data.whoEligible) {
        case 'Admin+Manager':
          eligible = [UserRoles.Manager, UserRoles.Admin];
          break;
        case 'Admin':
          eligible = [UserRoles.Admin];
          break;
      }

      this._behaviorApiService
        .createBehavior({
          body: {
            name: data.name,
            points: data.points,
            eligible,
            isValue: data.isValue,
            description: data.description,
            tenantId: this.tenant._id
          }
        })
        .subscribe(() => {
          this._getBehaviors();
        });
    }
  }

  protected async onNonSysBehaviorClick(behavior: BehaviorModel) {
    const { role, data } = await showModal<INonSystemEntity>(
      {
        component: EditNonSystemEntityModalComponent,
        props: {
          entity: {
            name: behavior.name,
            points: behavior.points,
            whoEligible: behavior.eligible.length
              ? behavior.eligible.length > 1
                ? 'Admin+Manager'
                : 'Admin'
              : 'Everyone',
            isValue: behavior.isValue,
            description: behavior.description
          }
        }
      },
      this._modalCtrl
    );

    if (role === 'confirm') {
      this.loading.present();

      let eligible: UserRoles[] = [];
      switch (data.whoEligible) {
        case 'Admin+Manager':
          eligible = [UserRoles.Manager, UserRoles.Admin];
          break;
        case 'Admin':
          eligible = [UserRoles.Admin];
          break;
      }

      this._behaviorApiService
        .updateBehavior({
          path: { id: behavior._id, tenantId: this.tenant._id },
          body: {
            ...behavior,
            name: data.name,
            points: data.points,
            eligible,
            isValue: data.isValue,
            description: data.description
          }
        })
        .subscribe(() => {
          this._getBehaviors();
        });
    } else if (role === 'delete') {
      this._behaviorApiService
        .deleteBehavior({ path: { id: behavior._id } })
        .subscribe(() => {
          this._getBehaviors();
        });
    }
  }

  protected async onSysBehaviorClick(behavior: BehaviorModel) {
    const { role, data } = await showModal<ISystemEntity>(
      {
        component: EditSystemEntityModalComponent,
        props: {
          entity: {
            name: behavior.name,
            points: behavior.points
          }
        }
      },
      this._modalCtrl
    );

    if (role === 'confirm') {
      this.loading.present();

      this._behaviorApiService
        .updateBehavior({
          path: { id: behavior._id, tenantId: this.tenant._id },
          body: {
            ...behavior,
            name: data.name,
            points: data.points
          }
        })
        .subscribe(() => {
          this._getBehaviors();
        });
    }
  }

  protected async onSysTaskClick(task: MongoStoredObject<TaskDto>) {
    const { role, data } = await showModal<ISystemEntity>(
      {
        component: EditSystemEntityModalComponent,
        props: {
          entity: {
            name: task.title,
            points: task.points
          }
        }
      },
      this._modalCtrl
    );

    if (role === 'confirm') {
      this.loading.present();

      const entity: ISystemEntity = data;

      this._taskListService
        .patchTask(task._id, {
          ...task,
          details: {
            ...task.details,
            attachments: task.details.attachments.map((a) => a._id.toString())
          },
          title: entity.name,
          points: entity.points
        })
        .subscribe(() => {
          this._getBehaviors();
        });
    }
  }

  protected handleNonSysBehaviorReorder({
    detail
  }: ItemReorderCustomEvent): void {
    detail.complete();

    if (detail.from !== detail.to) {
      const organizedIds = this.recognitionBehaviors.map((b) => b._id);
      reorderItems(organizedIds, detail.from, detail.to);

      this._behaviorApiService
        .moveBehavior({
          body: { organizedIds }
        })
        .subscribe(() => {
          this._getBehaviors();
        });
    }
  }
}
